import React from "react"
import { Link } from "gatsby"
import Moment from "react-moment"

const Card = ({ article }) => {
  return (
    <Link to={`/blog/${article.node.slug}`} className="">
      <div className="">
        <div className="">
          <img
            src={article.node.image.url}
            alt={article.node.image.url}
            height="100"
            className="rounded-lg border-0 border-gray-200 w-full h-48 object-cover"
          />
        </div>
        <div className="">
          <div className="flex justify-between text-sm text-gray-400 mt-5">
            <Moment format="MMM Do YYYY">{article.node.published_at}</Moment>
            <span className="bg-orange-500 text-xs text-white px-2 py-1 rounded-lg">
              {article.node.category.name}
            </span>
          </div>
          <p className="font-bold text-gray-800 text-2xl leading-tight mt-2">
            {article.node.title}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default Card
