import React from "react"
import Card from "./card"

const Articles = ({ articles }) => {
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 p-10 md:p-0">
      {articles.map((article, i) => {
        return <Card article={article} key={`article__${article.node.id}`} />
      })}
    </div>
  )
}

export default Articles
