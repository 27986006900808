import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlesComponent from "../components/articles"
import Footer from "../components/footer"

import logo from "../images/logo.png"
import waveReverse from "../images/wave_reverse.svg"

const BlogPage = () => (
  <Layout>
    <SEO
      title="Blog"
      description="Grow your property business with Flow’s suite of products."
      image={logo}
    />
    <StaticQuery
      query={graphql`
        query {
          allStrapiBlogArticle(sort: {fields: published_at, order: DESC}) {
            edges {
              node {
                strapiId
                slug
                title
                published_at
                category {
                  name
                }
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="md:mt-12">

          <section id="top" className="w-full md:px-10 relative">
            <div className="container mx-auto py-5 border-b border-gray-200 md:mb-10">
              <header className="flex flex-col-reverse md:flex-row w-full top-0 p-10 md:p-0">
                <div className="w-full md:mt-0">
                  <div className="text-center md:text-left">
                    <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight animated fadeIn">The <span className="wrap">Flow</span> blog</h1>
                    <p className="inline-block text-md md:text-xl font-light text-gray-400 mx-auto mt-3">Grow your property business with Flow’s suite of products.</p>
                  </div>
                </div>
              </header>
            </div>
          </section>

          <ArticlesComponent articles={data.allStrapiBlogArticle.edges} />

        </div>
      )}
    />
    
    <img className="w-full" src={waveReverse} alt="" />

    <Footer />
  </Layout>
)

export default BlogPage
